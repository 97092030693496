body, html {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  min-width: 100%;
  min-height: 100%;
  background: rgba(2, 0, 31, 1);
  color: #dbdbdb;
  font-family: "Lora";
  overflow: auto;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}


/* The entire scrollbar */
::-webkit-scrollbar {
  width: 10px; /* Vertical scrollbar width */
  height: 10px; /* Horizontal scrollbar height */
}

/* The scrollbar track */
/* ::-webkit-scrollbar-track {
  background: transparent;
} */

/* The scrollbar thumb (the draggable area) */
::-webkit-scrollbar-thumb {
  background: rgb(85, 84, 100);
  border-radius: 5px;
}

.App {
  width: 100%;
  height: 100%;
}
