.slide {
  transition: opacity 0.5s ease;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}
